import { Button, Card, Input } from '@/components'

import { newRelicFormInteracted } from '@/utils/trackingNewRelic'

import { useSoftlead } from './useSoftlead'

import { validators } from './validators'

import styles from './Softlead.module.scss'

export const Softlead = ({ topContent, basePayload, onCatch }) => {
  const { control, isValid, isLoading, onEmailBlur, handleSubmit, onSubmit } =
    useSoftlead({ basePayload, onCatch })

  return (
    <>
      <div className={styles.softlead}>
        {topContent}

        <form
          id="softlead-form"
          className={styles.softlead__form}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Input.Cnpj
            id="cnpj"
            name="cnpj"
            label="CNPJ da minha empresa"
            placeholder="XX.XXX.XXX/0001-XX"
            control={control}
            rules={validators.cnpj}
            onBlur={newRelicFormInteracted('CNPJ')}
          />

          <Input.Text
            id="name"
            name="name"
            label="Nome e sobrenome"
            placeholder="João da Silva"
            control={control}
            rules={validators.name}
            onBlur={newRelicFormInteracted('Nome e sobrenome')}
          />

          <Input.Email
            id="email"
            name="email"
            label="Email"
            placeholder="email@example.com"
            control={control}
            rules={validators.email}
            onBlur={onEmailBlur}
          />

          <Input.Phone
            id="phone"
            name="phone"
            label="Telefone"
            placeholder="(XX) 9XXXX-XXXX"
            control={control}
            rules={validators.phone}
            onBlur={newRelicFormInteracted('Telefone')}
          />

          <Card size="xs" bgColor="gray" className={styles.softlead__terms}>
            <span>
              Ao continuar você autoriza que os dados fornecidos sejam
              utilizados&nbsp;
            </span>
            <span>
              com a finalidade específica de ser contatado por e-mail,
              telefone,&nbsp;
            </span>
            <span>
              Whatsapp ou SMS, para recebimento de ofertas de produtos e
              serviços&nbsp;
            </span>
            <span>da Equifax | BoaVista.&nbsp;</span>
          </Card>
        </form>
      </div>

      <Button
        type="submit"
        variant="primary"
        id="softlead-continue-button"
        className={styles.softlead__submitButton}
        disabled={!isValid}
        isLoading={isLoading}
        onClick={handleSubmit(onSubmit)}
      >
        Continuar
      </Button>
    </>
  )
}
